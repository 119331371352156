<div class="comment-container">
    <div class="text-left privilege-custom">
        <div class="btn-group text-right button-group-custom">
          <div class="btn-group ml-2"
              ngbDropdown
              [autoClose]="true"
              container="body"
              role="group"
              placement="left"
              aria-label="Button group with nested dropdown">
              <svg-icon [src]="view.label"
                ngbDropdownToggle
                [svgStyle]="{
                    'width': '15px',
                    'color': '#4D4D4D'
                }"
                class="pointer pr-1"></svg-icon>
            <span class="pt-custom pointer pr-1"
                ngbDropdownToggle>{{view.name|translate}}</span>
            <svg-icon src="assets/images/icons/arrow_down.svg"
                ngbDropdownToggle
                [svgStyle]="{'width': '10px'}"
                class="pointer pb-1"></svg-icon>
            <div class="dropdown-menu"
                ngbDropdownMenu>
              <button ngbDropdownItem
                    (click)="viewStatusComment('public')">
                <svg-icon src="assets/images/icons/earth-europe-africa.svg"
                    [svgStyle]="{'width': '13px'}"
                    class="pointer pr-2"></svg-icon>
                <span>{{'MEMOS.PUBLIC'|translate}}</span>
              </button>
              <button ngbDropdownItem
                    (click)="viewStatusComment('private')">
                <svg-icon src="assets/images/icons/user-line.svg"
                    [svgStyle]="{'width': '13px'}"
                    class="pointer pr-2"></svg-icon>
                <span>{{'MEMOS.ONLY-ME'|translate}}</span>
              </button>
              <button ngbDropdownItem
                    (click)="viewStatusComment('shared', customizeShareModal)">
                <svg-icon src="assets/images/icons/user-multiple.svg"
                    [svgStyle]="{'width': '13px'}"
                    class="pointer pr-2"></svg-icon>
                <span>{{'MEMOS.CUSTOMIZE-SHARE'|translate}}</span>
              </button>
              <button ngbDropdownItem
                    (click)="viewStatusComment('unshared', customizeUnshareModal)">
                <svg-icon src="assets/images/icons/group-access.svg"
                    [svgStyle]="{'width': '13px'}"
                    class="pointer pr-2"></svg-icon>
                <span>{{'MEMOS.CUSTOMIZE-UNSHARE'|translate}}</span>
              </button>
            </div>
          </div>
        </div>
    </div>

    <div class="menu-bar d-flex flex-nowrap">
        <div class="d-flex flex-column" style="flex-grow: 1; width: 100%;">
            <div #messageInput
                id="messageInput"
                *ngIf="!isNonUser"
                class="textarea-post post-width custom-box"
                [ngClass]="{ 'custom-height': isTextConfig }"
                [attr.placeholder]="'MEMOS.COMMENT-HERE'|translate"
                [(ngModel)]="input.message"
                [mention]="profileList"
                [mentionConfig]="mentionConfig"
                (searchTerm)="triggerDebouncer($event)"
                (opened)="mentionOpened()"
                (closed)="mentionClosed()"
                (keydown.enter)="handleEnterKey($event)"
                (ngModelChange)="onInputMessage($event)"
                [preventEnter]="booleanPreventEnter"
                appContentEditable
                appMentionHighlight>
            </div>
          <div class="custom-config">
            <div class="custom-toolbar">
              <div *ngIf="isTextConfig" class="custom-d-flex">
                <button type="button" class="btn btn-outline-secondary custom-btn" (click)="setStyle('bold')">
                  <svg-icon class="pointer" src="assets/svg/icon-profile/text--bold.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.bold ? '#76aec4': '#595959'}"></svg-icon>
                </button>
                <button type="button" class="btn btn-outline-secondary custom-btn" (click)="setStyle('italic')">
                  <svg-icon class="pointer" src="assets/svg/icon-profile/text--italic.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.italic ? '#76aec4': '#595959'}"></svg-icon>
                </button>
                <button type="button" class="btn btn-outline-secondary custom-btn"
                        (click)="setStyle('underline')">
                  <svg-icon class="pointer" src="assets/svg/icon-profile/text--underline.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.underline ? '#76aec4': '#595959'}"></svg-icon>
                </button>
                <button type="button" class="btn btn-outline-secondary custom-btn"
                        [ngClass]="{'text-hover': toggleList.color}"
                        (click)="onColorPicker()"
                        (clickOutside)="showColorPicker = false;">
                  <svg-icon class="pointer" src="assets/svg/icon-profile/text--color.svg"
                            [svgStyle]="{'height.px': 14,'width.px': 14,
                  'fill': toggleList.color ? '#76aec4': '#595959',
                  'border-bottom': selectedColor && toggleList.color
                  ? '2px solid' + ' '+ selectedColor
                  : '2px solid #595959'}">
                  </svg-icon>
                </button>
                <div *ngIf="showColorPicker"
                     class="color-popup-window">
                  <div class="color-list">
                    <button class="color-item-border pointer"
                            *ngFor="let color of colorList"
                            [style.border-color]="color === selectedColor ? color : 'white'"
                            (click)="onSelectedColor(color)">
                                    <span class="color-item"
                                          [style.background]="color"></span>
                    </button>
                  </div>
                </div>
                      <button type="button" class="btn btn-outline-secondary custom-btn"
                              (click)="setStyle('strikeThrough')">
                        <svg-icon class="pointer" src="assets/svg/icon-profile/text--strikethrough.svg"
                                  [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.strikeThrough ? '#76aec4': '#595959'}"></svg-icon>
                      </button>

                      <button type="button" class="btn btn-outline-secondary custom-btn"
                              (click)="onSelectHeader()"
                              (clickOutside)="showHeaderConfig = false">
                        <svg-icon class="pointer" src="assets/svg/icon-profile/text--font.svg"
                                  [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.header ? '#76aec4': '#595959'}"></svg-icon>
                      </button>
                        <div *ngIf="showHeaderConfig"
                            class="header-popup-window">
                        <div class="header-list">
                            <button class="header-item-border pointer"
                                    *ngFor="let fontSize of headerList"
                                    (click)="onSelectedEachHeader(fontSize.size)">
                            <span class="header-item"
                                    [ngClass]="{'text-hover': selectedHeader === fontSize.size}">{{ fontSize.format }}</span>
                            </button>
                        </div>
                        </div>
                      <button type="button" class="btn btn-outline-secondary custom-btn"
                              (click)="setStyle('insertUnorderedList')">
                        <svg-icon class="pointer" src="assets/svg/icon-profile/list--bulleted.svg"
                                  [svgStyle]="{'height.px': 18,'width.px': 18,
                  'fill': toggleList.insertUnorderedList ? '#76aec4': '#595959'}"></svg-icon>
                      </button>
              </div>
                <button class="btn btn-outline-secondary custom-btn"
                        type="button" (click)="onSelectTextConfig()">
                <svg-icon class="pointer" src="assets/svg/icon-profile/text-annotation-toggle.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                            'fill': isTextConfig ? '#76aec4': '#595959'}"></svg-icon>
                </button>
                <button class="btn btn-outline-secondary custom-btn"
                        type="button">
                    <svg-icon class="pointer" src="assets/svg/icon-profile/at.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                    'fill': '#595959'}"
                    (click)="openModalTag(dropdownTagModal)"></svg-icon>
                </button>
                <button class="btn btn-outline-secondary custom-btn"
                        type="button">

                <svg-icon class="pointer" src="assets/svg/icon-profile/attachment.svg"
                            [svgStyle]="{'height.px': 18,'width.px': 18,
                'fill': '#595959'}"
                            (click)="image.click()"></svg-icon>
                </button>
                <input type="file"
                    accept="audio/*,video/*,image/*,.csv,.ppt,.pptx,.pdf, application/
                            vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-excel, application/msword, application/
                            vnd.openxmlformats-officedocument.wordprocessingml.document"
                    class="form-control m-t-10"
                    [(ngModel)]="fileUpload"
                    (change)="uploadFile($event)"
                    #image
                    hidden>
                    <button class="btn btn-outline-secondary custom-btn button-white"
                        type="button"
                        (click)="addComment('send')">
                        <svg-icon class="pointer" src="assets/svg/icon-profile/send--alt.svg"
                                [svgStyle]="{'height.px': 18,'width.px': 18,
                        'fill': '#595959'}"></svg-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- modal -->
<ng-template #customizeShareModal
    let-c="close"
    let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            {{"MEMOS.CUSTOMIZE-SHARE"| translate}}
        </h4>
        <!-- <button type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()">
            <em class="fas fa-times"></em>
        </button> -->
    </div>
    <div class="modal-body filter custom-box" style="padding: 1rem 0 0;">
        <div class="form-group col-12">
            <label for="shareTo">
                {{"MEMOS.SHARE-TO"| translate}}
            </label>
            <ng-select
                [items]="people$ | async"
                placeholder="{{'MEMOS.SELECT'|translate}}"
                [loading]="peopleLoading"
                [notFoundText]="'SELECT.No items found'|translate"
                [typeahead]="peopleInput$"
                [minTermLength]="2"
                [typeToSearchText]="'user.type-search' | translate"
                (ngModelChange)="onChangePeople($event)"
                (select)="onChangePeople($event)"
                [(ngModel)]="peopleId">
                <ng-template ng-label-tmp
                    let-item="item"
                    let-clear="clear">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp
                        let-item="item">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
            <p class="condition-text">
                {{"MEMOS.CONDITION-SHARE"| translate}}
            </p>
        </div>
        <div class="form-group col-12 custom-scroll"
            *ngIf="peopleList.length!==0">
            <table class="table m-0 d-md-table">
                <tbody>
                  <tr *ngFor="let list of peopleList; let i = index;"
                    class="memo-child">
                    <td class="text-muted v-middle p-1">
                        <div class="row no-gutters">
                            <div class="profile-photo col-2 mr-2">
                                <img class="profile-pic"
                                    [src]="(list.context?.photo | secure | async) || 'assets/images/person.png'">
                            </div>
                            <div class="col">
                                <span class="ng-value-label">{{ list.label }}</span><br/>
                                <small><em>{{ list.context?.job_position }}</em></small>
                            </div>
                        </div>
                    </td>
                    <td class="v-middle text-right">
                      <span class="pointer"
                        (click)="deleteUsers(list.value)">
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer" style="padding: 0 1rem 1rem">
        <button
            [ngStyle]="{'border-color':themeList?.header_table_color,
                        'color':themeList?.header_table_color}"
            class="btn eh-button my-3"
            type="button"
            (click)="closeModal()">
            {{ 'MEMOS.CANCEL' | translate }}
        </button>
        <button appSetTheme
            class="btn eh-button my-3"
            [disabled]="peopleList.length===0"
            (click)="submitShare()"
            type="button">
          {{ 'MEMOS.CONFIRM' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #customizeUnshareModal
    let-c="close"
    let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
          {{"MEMOS.CUSTOMIZE-UNSHARE"| translate}}
        </h4>
        <!-- <button type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()">
          <em class="fas fa-times"></em>
        </button> -->
    </div>
    <div class="modal-body filter custom-box" style="padding: 1rem 0 0;">
        <div class="form-group col-12">
            <label for="shareTo">
                {{"MEMOS.UNSHARE-TO"| translate}}
            </label>
            <ng-select
                [items]="people$ | async"
                placeholder="{{'MEMOS.SELECT'|translate}}"
                [loading]="peopleLoading"
                [notFoundText]="'SELECT.No items found'|translate"
                [typeahead]="peopleInput$"
                [minTermLength]="2"
                [typeToSearchText]="'user.type-search' | translate"
                (ngModelChange)="onChangePeople($event)"
                (select)="onChangePeople($event)"
                [(ngModel)]="peopleId">
                <ng-template ng-label-tmp
                    let-item="item"
                    let-clear="clear">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp
                        let-item="item">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
            <p class="condition-text">
                {{"MEMOS.CONDITION-UNSHARE"| translate}}
            </p>
        </div>
        <div class="form-group col-12 custom-scroll"
                *ngIf="peopleList.length!==0">
            <table class="table m-0 d-md-table">
                <tbody>
                  <tr *ngFor="let list of peopleList; let i = index;"
                    class="memo-child">
                    <td class="text-muted v-middle p-1">
                        <div class="row no-gutters">
                            <div class="profile-photo col-2 mr-2">
                                <img class="profile-pic"
                                    [src]="(list.context?.photo | secure | async) || 'assets/images/person.png'">
                            </div>
                            <div class="col">
                                <span class="ng-value-label">{{ list.label }}</span><br/>
                                <small><em>{{ list.context?.job_position }}</em></small>
                            </div>
                        </div>
                    </td>
                    <td class="v-middle text-right">
                      <span class="pointer"
                        (click)="deleteUsers(list.value)">
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer" style="padding: 0 1rem 1rem;">
        <button
            [ngStyle]="{'border-color':themeList?.header_table_color,
                        'color':themeList?.header_table_color}"
            class="btn eh-button my-3"
            type="button"
            (click)="closeModal()">
            {{ 'MEMOS.CANCEL' | translate }}
        </button>
        <button appSetTheme
            class="btn eh-button my-3"
            [disabled]="peopleList.length===0"
            (click)="submitShare()"
            type="button">
          {{ 'MEMOS.CONFIRM' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #dropdownTagModal
    let-c="close"
    let-d="dismiss">
    <div class="modal-header border-0">
        <h4 class="modal-title">
          {{ "MEMOS.SEARCH-LIST" | translate}}
        </h4>
        <!-- <button type="button"
            class="close"
            aria-label="Close"
            (click)="closeModalTag()">
          <em class="fas fa-times"></em>
        </button> -->
    </div>
    <div class="modal-body filter body-container">
        <div class="p-0 w-100 mb-3">
            <ng-select
                [items]="peopleTag$ | async"
                placeholder="{{'MEMOS.SELECT'|translate}}"
                [loading]="peopleLoadingTag"
                [notFoundText]="'SELECT.No items found'|translate"
                [typeahead]="peopleInputTag$"
                [minTermLength]="2"
                [typeToSearchText]="'user.type-search' | translate"
                (ngModelChange)="onChangePeopleTag($event)"
                (select)="onChangePeopleTag($event)"
                [(ngModel)]="peopleIdTag">
                <ng-template ng-label-tmp
                    let-item="item"
                    let-clear="clear">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left mr-4">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp
                        let-item="item">
                    <div class="row no-gutters">
                        <div class="profile-photo col-1 text-left mr-4">
                            <img class="profile-pic"
                                    [src]="(item.context?.photo | secure | async) || 'assets/images/person.png'">
                        </div>
                        <div class="col">
                            <span class="ng-value-label">{{ item.label }}</span><br/>
                            <small><em>{{ item.context?.job_position }}</em></small>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div class="custom-scroll-search">
            <table class="table m-0 d-md-table">
                <tbody *ngIf="peopleListTag.length!==0; else emptyList">
                  <tr *ngFor="let list of peopleListTag; let i = index;"
                    class="memo-child">
                    <td class="text-muted v-middle p-1">
                        <div class="row no-gutters">
                            <div class="profile-photo col-1 mr-4">
                                <img class="profile-pic"
                                    [src]="(list.context?.photo | secure | async) || 'assets/images/person.png'">
                            </div>
                            <div class="col pt-3">
                                <span class="ng-value-label">{{ list.label }}</span>
                            </div>
                        </div>
                    </td>
                    <td class="v-middle text-right">
                      <span class="pointer"
                        (click)="deleteUsersTag(list.value)">
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <ng-template #emptyList>
                    <div class="p-0 text-center">
                        <span>{{"MEMOS.NO-DATA" | translate}}</span>
                    </div>
                </ng-template>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button
            [ngStyle]="{'border-color':themeList?.header_table_color,
                        'color':themeList?.header_table_color}"
            class="btn eh-button my-3"
            type="button"
            (click)="closeModalTag()">
            {{ 'MEMOS.CANCEL' | translate }}
        </button>
        <button appSetTheme
            class="btn eh-button my-3"
            [disabled]="peopleListTag.length===0"
            type="button"
            (click)="addMentionSelect()">
          {{ 'MEMOS.CONFIRM' | translate }}
        </button>
    </div>
</ng-template>
