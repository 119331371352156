import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DropdownItem } from '../../../../../shared/models/common.model';
import { User } from '../../../../../shared/models/user.models';
import { DropdownService } from '../../../../../shared/service/dropdown.service';
import { CertificateStatus } from '../../../../certificate/shared/certificate.model';
import { Member } from '../../../../loa/shared/loa.model';
import { ApproverDropdown } from '../../../model/memo.model';
import { DdocLawSectionObj } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.component';
@Component({
  selector: 'app-approval-dropdown',
  templateUrl: './approval-dropdown.component.html',
  styleUrls: ['./approval-dropdown.component.scss'],
})
export class ApprovalDropdownComponent implements OnChanges, OnInit {
  certificateStatus: CertificateStatus = null;
  people$: Observable<ApproverDropdown[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;

  @Input() clearable = false;
  @Input() selectedApprovals: number[] = [];
  @Input() approval: Partial<Member>;
  @Input() disabled = false;
  @Input() disabledApprover = false;
  @Input() freeLawSelection = false;
  @Input() enableAttachSignature = false;
  @Input() ddocEnable = false;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() allowLOAChange = true;
  @Output() approvalChange = new EventEmitter();
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();
  @Input() department: number[] = [];

  constructor(private dropdownService: DropdownService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ddocEnable) {
      if (!changes.ddocEnable.currentValue) {
        this.ddocLawSection = { lawSection: null };
      }
    }
  }

  ngOnInit(): void {
    this.setPeople();
  }

  setPeople(): void {
    this.people$ = concat(
      of([] as ApproverDropdown[]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params: { [k: string]: any } = {
            type: 'people_with_sign_hide_or_unhide',
            all: 'true',
            group: 'General',
            with_signature: true,
            department: this.department || null,
            query,
          };
          if (this.enableAttachSignature) {
            params.with_signature = true;
          }
          return this.dropdownService.getDropdown(params).pipe(
            map((obj) =>
              obj.people_with_sign_hide_or_unhide.map(
                (
                  people: DropdownItem<number, PeopleDropdownContext>,
                ) => {
                  const approver: ApproverDropdown = {
                    person: people.value,
                    full_name: people.label,
                  };
                  if (this.enableAttachSignature) {
                    approver.signature = people.context.signature;
                  }
                  return approver;
                },
              ),
            ),
            map((people) => {
              return people.filter(
                (person) =>
                  !this.selectedApprovals.includes(person.person),
              );
            }),
          );
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  onDropdownChange(dropdown: ApproverDropdown): void {
    this.approvalChange.emit(dropdown);
  }
}

interface PeopleDropdownContext extends Partial<User> {
  signature: string;
}
