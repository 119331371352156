export const FONT_LIST = [
  { name: 'THSarabunNew', value: 'THSarabunNew' },
  { name: 'THSarabunNewBold', value: 'THSarabunNewBold' },
  { name: 'THSarabunNewItalic', value: 'THSarabunNewItalic' },
  { name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic' },
];

export const DATE_TYPE_LIST = [
  {
    value: 'published_at',
    label: 'Publish Date',
    th: 'วันที่เผยแพร่',
  },
  {
    value: 'finished_at',
    label: 'Finished Date',
    th: 'วันที่เสร็จสิ้น',
  },
  {
    value: 'custom_date',
    label: 'Custom Date',
    th: 'กำหนดวันที่เอง',
  },
];

export const SIGNATURE_COLOR_LIST = [
  '#000000',
  '#4169e1',
  '#EB2B36',
  '#266D1F',
];

export const COMMENT_COLOR_PICKER = [
  '#000000',
  '#f44336',
  '#2096f3',
  '#4caf50',
  '#ffc008',
  '#9e9e9e',
];
