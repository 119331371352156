import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from 'src/app/shared/service/sweet-alert.service';
import * as _ from 'lodash';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';
import { AlertService } from '../../../core/services/alert.service';
import { Store } from '@ngxs/store';
import { People } from '../../../modules/memos/model/dropdown.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { ApproverDropdown } from 'src/app/modules/memos/model/memo.model';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { User } from '../../models/user.models';
import { DropdownItem } from '../../models/common.model';
import {
  faAt,
  faBold,
  faFont,
  faHeading,
  faItalic,
  faList,
  faPaperclip,
  faPaperPlane,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { COMMENT_COLOR_PICKER } from 'src/app/modules/memos/service/upload-memo.constants';
import { NewsFeedInput } from 'src/app/modules/new-feed/shared/news-feed.models';

export interface MentionProfile {
  id: number;
  full_name: string;
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @ViewChild('messageInput')
  messageInput: ElementRef;

  photo = localStorage.getItem('photoUrl');
  // newComment = '';
  fileUpload: File;
  profileList = [];
  debouncer = new Subject<string>();
  alreadySearchText = [''];
  userList = [];
  userDict = {};
  isMentionOpen = false;
  isNonUser = false;

  mentionConfig = {
    triggerChar: '@',
    labelKey: 'label',
    maxItems: 10,
    allowSpace: true,
    dropUp: true,
    mentionSelect: (mention: People): string => {
      return `@[${mention.value}:${mention.label}]`;
    },
  };

  @Input() memoId: number;
  @Input() key = null;
  @Output() submitted = new EventEmitter();
  @Output() uploaded = new EventEmitter();

  publicView = {
    name: 'MEMOS.PUBLIC',
    value: 'public',
    label: 'assets/images/icons/earth-europe-africa.svg',
  };
  onlyView = {
    name: 'MEMOS.ONLY-ME',
    value: 'private',
    label: 'assets/images/icons/user-line.svg',
  };
  customizeShareView = {
    name: 'MEMOS.CUSTOMIZE-SHARE',
    value: 'shared',
    label: 'assets/images/icons/user-multiple.svg',
  };
  customizeUnShareView = {
    name: 'MEMOS.CUSTOMIZE-UNSHARE',
    value: 'unshared',
    label: 'assets/images/icons/group-access.svg',
  };
  view = this.publicView;
  modal: NgbModalRef;
  themeList: ThemeList;
  // dropdown people
  peopleId = null;
  peopleList: Array<DropdownItem> = [];
  peopleListBook: Array<DropdownItem> = [];
  people$: Observable<{ label: string; value: number }[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  showDropDown: boolean;

  // dropdown people Tag
  peopleIdTag = null;
  peopleListTag: Array<DropdownItem> = [];
  peopleListBookTag: Array<DropdownItem> = [];
  peopleTag$: Observable<{ label: string; value: number }[]>;
  peopleInputTag$ = new Subject<string>();
  peopleLoadingTag = false;

  // text area new
  paperclip = faPaperclip;
  paperPlan = faPaperPlane;
  boldText = faBold;
  italicText = faItalic;
  underlineText = faUnderline;
  bulletText = faList;
  strikeText = faStrikethrough;
  faFont = faFont;
  paperMention = faAt;
  headerText = faHeading;
  faAt = faAt;

  isTextConfig = false;
  toggleList = {
    bold: false,
    italic: false,
    strikeThrough: false,
    insertUnorderedList: false,
    underline: false,
    mention: false,
    color: false,
    header: false,
  };
  showColorPicker = false;
  showHeaderConfig = false;
  headerList = [
    { format: 'Small', size: '1' },
    { format: 'Normal', size: '2' },
    { format: 'Medium', size: '3' },
    { format: 'Medium-large', size: '4' },
    { format: 'Big', size: '5' },
    { format: 'Very big', size: '6' },
    { format: 'Maximum', size: '7' },
  ];
  colorList = COMMENT_COLOR_PICKER;
  selectedHeader = this.headerList[1].size;
  selectedColor = this.colorList[0];
  booleanPreventEnter = false;
  input: NewsFeedInput = {
    message: '',
    mentionIDs: [] as string[],
    attachments: [] as File[],
  };

  constructor(
    private alert: AlertService,
    private swal: SweetAlertService,
    private translate: TranslateService,
    private dropdownService: DropdownService,
    private store: Store,
    private modalService: NgbModal,
    private themeService: ThemeService,
  ) {
    this.showDropDown = true;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.isNonUser = this.store.selectSnapshot<boolean>(
      (state) => state.auth.is_custom,
    );
    if (!this.isNonUser) {
      this.getUserList();
    }
    this.initPeoplePipe();
  }

  mentionOpened(): void {
    this.isMentionOpen = true;
  }

  mentionClosed(): void {
    this.isMentionOpen = false;
  }

  handleEnterKey(event?: Event): void {
    if (!this.isMentionOpen) {
      this.setMention();
      this.addComment();
    }
  }

  addComment(event?): void {
    let comment;
    if (this.isNonUser) {
      comment = [{ newComment: this.input.message }];
    } else {
      const mentionedPerson = [];
      const mentions = this.messageInput.nativeElement.children;
      Array.from(mentions).forEach((mention: HTMLElement) => {
        const mentionID = mention.getAttribute('data-id');
        if (mentionID && !mentionedPerson.includes(mentionID)) {
          mentionedPerson.push(+mentionID);
        }
      });
      if (
        this.toggleList.insertUnorderedList &&
        this.input.message.match('<div><br></div>' || '<li><br></li>')
      ) {
        const charactor = this.input.message.split('<div><br></div>');
        this.input.message = charactor[0];
        this.booleanPreventEnter = true;
      } else if (!this.toggleList.insertUnorderedList) {
        this.booleanPreventEnter = true;
      } else if (
        this.toggleList.insertUnorderedList &&
        event === 'send'
      ) {
        this.booleanPreventEnter = true;
      } else {
        this.booleanPreventEnter = false;
      }
      comment = [
        {
          permission_type: this.view,
          shared_user_list: this.peopleList,
          newComment: this.input.message,
          mentionedPerson,
        },
      ];
    }
    if (this.booleanPreventEnter) {
      this.submitted.emit(comment);
    }
    setTimeout(() => {
      if (this.booleanPreventEnter) {
        this.input.message = '';
        this.booleanPreventEnter = false;
        this.selectedColor = this.colorList[0];
        Object.keys(this.toggleList).forEach((key) => {
          if ((this.toggleList[key] = true)) {
            document.execCommand('undo', false, null);
          }
          this.toggleList[key] = false;
        });
      }
    }, 10);
  }

  getUserList(): void {
    if (this.key) {
      this.getUserListWithKey();
      return;
    }
    const params = {
      type: 'people_for_mention',
      memo_id: this.memoId.toString(),
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: any) => {
        this.profileList = dropdown.people_for_mention;
      },
      (error: { error: string }) => {
        if (this.translate.currentLang === 'th') {
          this.swal.toastNotification({
            type: 'error',
            content: 'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้',
          });
        } else {
          this.swal.toastNotification({
            type: 'error',
            content: `Can't get mention users.`,
          });
        }
      },
    );
  }

  getUserListWithKey(): void {
    const header = { Authorization: this.key };
    const params = {
      type: 'people_for_mention',
      memo_id: this.memoId.toString(),
    };
    this.dropdownService
      .getDropdownWithHeader(header, params)
      .subscribe(
        (dropdown: DropdownResponse) => {
          this.profileList = dropdown.people_for_mention;
        },
        (error: { error: string }) => {
          if (this.translate.currentLang === 'th') {
            this.swal.toastNotification({
              type: 'error',
              content:
                'ไม่สามารถโหลดข้อมูล user สำหรับการกล่าวถึงได้',
            });
          } else {
            this.swal.toastNotification({
              type: 'error',
              content: `Can't get mention users.`,
            });
          }
        },
      );
  }

  uploadFile(fileInput): void {
    if (fileInput.target.files[0].size / (1000 * 1000) >= 30) {
      this.alert.error('กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 30 MB');
      this.fileUpload = null;
      return;
    }
    const params = [
      {
        permission_type: this.view,
        shared_user_list: this.peopleList,
      },
    ];
    this.uploaded.emit({ file: fileInput, params: params });
    fileInput.target.value = '';
  }

  triggerDebouncer(event): void {
    this.debouncer.next(event);
  }

  viewStatusComment(status: string, content?): void {
    this.peopleList = [];
    if (status === 'public') {
      this.view = this.publicView;
    } else if (status === 'private') {
      this.view = this.onlyView;
    } else if (status === 'shared') {
      this.view = this.customizeShareView;
      this.modal = this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
      });
    } else if (status === 'unshared') {
      this.view = this.customizeUnShareView;
      this.modal = this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
      });
    }
  }

  closeModal(): void {
    this.view = this.publicView;
    this.modal.close();
    this.peopleList = [];
  }

  initPeoplePipe(): void {
    this.people$ = concat(
      of([]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params = {
            type: 'people_for_comment',
            memo_id: this.memoId.toString(),
            query,
          };

          return this.dropdownService.getDropdown(params).pipe(
            map((dropdown: DropdownResponse) => {
              const peopleIds = this.peopleList.map(
                (eachPerson) => eachPerson.value,
              );
              const searchList = _.cloneDeep(
                dropdown.people_for_comment,
              );
              return searchList.filter(
                (eachPerson) =>
                  peopleIds.indexOf(eachPerson.value) === -1,
              );
            }),
          );
        }),
        catchError(() => of([])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  onChangePeople(value: any): void {
    if (value) {
      if (
        this.peopleList.filter((p) => p.value === value.value)
          .length === 0
      ) {
        this.peopleList.push(value);
      }
    }
    this.showDropDown = false;
    setTimeout(() => {
      this.peopleId = null;
      this.showDropDown = true;
    }, 1);
  }

  deleteUsers(value: number): void {
    this.peopleList = this.peopleList.filter(
      (t) => t.value !== value,
    );
  }

  submitShare(): void {
    if (
      (this.view.value === 'shared' ||
        this.view.value === 'unshared') &&
      this.peopleList.length === 0
    ) {
      return;
    }
    this.modal.close();
  }

  openModalTag(content): void {
    this.modal = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
    });
    this.initPeoplePipeTag();
  }

  initPeoplePipeTag(): void {
    this.peopleTag$ = concat(
      of([]),
      this.peopleInputTag$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoadingTag = true)),
        switchMap((query) => {
          const params = {
            type: 'people_for_comment',
            memo_id: this.memoId.toString(),
            query,
          };

          return this.dropdownService.getDropdown(params).pipe(
            map((dropdown: DropdownResponse) => {
              const peopleIds = this.peopleListTag.map(
                (eachPerson) => eachPerson.value,
              );
              const searchList = _.cloneDeep(
                dropdown.people_for_comment,
              );
              return searchList.filter(
                (eachPerson) =>
                  peopleIds.indexOf(eachPerson.value) === -1,
              );
            }),
          );
        }),
        catchError(() => of([])),
        tap(() => (this.peopleLoadingTag = false)),
      ),
    );
  }

  onChangePeopleTag(value: any): void {
    if (value) {
      if (
        this.peopleListTag.filter((p) => p.value === value.value)
          .length === 0
      ) {
        this.peopleListTag.push(value);
      }
    }
    this.showDropDown = false;
    setTimeout(() => {
      this.peopleIdTag = null;
      this.showDropDown = true;
    }, 1);
  }

  deleteUsersTag(value: number): void {
    this.peopleListTag = this.peopleListTag.filter(
      (t) => t.value !== value,
    );
  }

  closeModalTag(): void {
    this.modalService.dismissAll();
    if (this.peopleListTag.length === 0) {
      this.input.message = '';
    }
  }

  addMentionSelect(): void {
    const people: People = {
      label: '',
      value: null,
    };
    let pretext: any;
    let result = '';
    for (const i in this.peopleListTag) {
      people.label = this.peopleListTag[i].label;
      people.value = this.peopleListTag[i].value;
      pretext = this.mentionConfig.mentionSelect(people);
      pretext = this.mentionConfig.labelKey =
        '<span data-id="' +
        people.value +
        '" style="color: #3294c1;" contenteditable="false">' +
        '@' +
        people.label +
        '</span>&nbsp;';
      result += pretext;
    }
    this.input.message = result;
    this.modalService.dismissAll();
  }

  // new text area for function
  onSelectTextConfig() {
    this.isTextConfig = !this.isTextConfig;
  }

  setStyle(style: string, completeValue?: string) {
    this.messageInput.nativeElement.focus();
    const value = completeValue ? completeValue : null;
    const checkCommand = document.execCommand(style, false, value);
    if (checkCommand) {
      this.toggleList[style] = !this.toggleList[style];
    }
  }

  onColorPicker() {
    this.showColorPicker = !this.showColorPicker;
    this.toggleList.color = true;
    this.showHeaderConfig = false;
  }

  onInputMessage(text: string): void {
    if (text.length === 0) {
      this.selectedHeader = this.headerList[1].size;
    }
  }

  onSelectedColor(color: string) {
    this.setStyle('ForeColor', color);
    this.selectedColor = this.toggleList.color
      ? color
      : this.colorList[0];
    this.showColorPicker = false;
  }

  onSelectHeader() {
    this.showHeaderConfig = !this.showHeaderConfig;
    this.toggleList.header = true;
    this.showColorPicker = false;
  }

  onSelectedEachHeader(size: string) {
    this.setStyle('FontSize', size);
    this.selectedHeader = size;
    this.showHeaderConfig = false;
  }

  setMention(): void {
    const mentions = this.messageInput.nativeElement.children;
    Array.from(mentions).forEach((mention: HTMLElement, inx) => {
      const mentionID = mention.getAttribute('data-id');
      if (mentionID && !this.input.mentionIDs.includes(mentionID)) {
        this.input.mentionIDs.push(mentionID);
      }
    });
  }
}
