<ng-template #confirmationModal let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <h4 class="modal-title flex-grow-1">
      {{contentText()}}
    </h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body custom-box filter"
    [class.p-4]="['approve'].includes(actionType) && !enableReason">
      <div *ngIf="['reject'].includes(actionType)">
        <span>{{ personLabel | translate }}</span>
        <div class="form-group mt-2">
          <ng-select
              [clearable]="false"
              placeholder="{{'MEMOS.SELECT' | translate}}"
              [items]="memoRejectList"
              bindValue="value"
              [notFoundText]="'SELECT.No items found'|translate"
              [searchable]="false"
              [(ngModel)]="pendingLevel"
              (change)="onRejectChange($event)">
              <ng-template
                  ng-label-tmp
                  let-item="item">
                  <div class="row no-gutters selected-text-label">
                    <div class="col">
                      <span class="ng-value-label">
                        {{ item.label }}
                      </span>
                    </div>
                    <div class="col text-right">
                      <span class="ng-value-label text-dropdown">
                        {{  translate.currentLang === 'th' ? item.context?.position_th:
                            item.context?.position_en }}
                      </span>
                    </div>
                  </div>
              </ng-template>
              <ng-template
                  ng-option-tmp
                  let-item="item">
                  <div class="row no-gutters">
                    <div class="col">
                      <span class="ng-value-label">
                        {{ item.label }}
                      </span>
                    </div>
                    <div class="col text-right">
                      <span class="ng-value-label text-dropdown">
                        {{  translate.currentLang === 'th' ? item.context?.position_th:
                            item.context?.position_en }}
                      </span>
                    </div>
                  </div>
              </ng-template>
          </ng-select>
        </div>
        <span>{{ reasonLabel | translate }}</span>
        <div class="form-group mt-2">
          <textarea
            class="form-control"
            fz-elastic
            [(ngModel)]="reason"
            (ngModelChange)="onReasonChange()">
          </textarea>
        </div>
      </div>
      <div *ngIf="!['reject'].includes(actionType) && enableReason">
        <span>{{ reasonLabel | translate }}</span>
        <div class="form-group mt-2">
          <textarea
            class="form-control"
            fz-elastic
            [(ngModel)]="reason"
            (ngModelChange)="onReasonChange()">
          </textarea>
        </div>
      </div>
      <div *ngIf="['terminate'].includes(actionType) && !enableReason">
        <span>{{ reasonLabel | translate }}</span>
        <div class="form-group mt-2">
          <textarea
            class="form-control"
            fz-elastic
            [(ngModel)]="reason"
            (ngModelChange)="onReasonChange()">
          </textarea>
        </div>
      </div>
      <div *ngIf="['approve'].includes(actionType) && !enableReason">
        <div class="form-group mt-2 text-center h5">
          <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
        </div>
      </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="close()">
      {{"MEMOS.CANCEL" | translate}}
    </button>
    <button class="btn btn-confirm"
        appSetTheme
        type="button"
        [disabled]="enableReason && !reason"
        (click)="submit()">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
  </div>
</ng-template>
