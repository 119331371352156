import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';
import { ThemeList } from '../../service/theme.service';

export type ActionType =
  | 'approve'
  | 'reject'
  | 'terminate'
  | 'pending';

@Component({
  selector: 'app-approval-confirm-modal',
  templateUrl: './approval-confirm-modal.component.html',
  styleUrls: ['./approval-confirm-modal.component.scss'],
})
export class ApprovalConfirmModalComponent {
  @ViewChild('confirmationModal', { static: true })
  confirmationModal: ElementRef;

  /** Define the default value of the `confirm` EventEmitter */
  @Input() confirmEmitValue: any;
  @Input() isSaved: boolean;
  @Input() enableReason = true;
  @Input() reason: string;
  /** Set the label of reason above textarea */
  @Input() reasonLabel = 'APPROVAL.REASON';
  @Input() personLabel = 'APPROVAL.PERSON';
  @Input() themeList: ThemeList;
  @Output() reasonChange = new EventEmitter<string>();

  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Input() memos;

  @Input() actionType: ActionType;
  memoRejectList = [];
  pendingLevel: any;
  typePosition: string;
  constructor(
    public modalService: NgbModal,
    public translate: TranslateService,
    private dropdownService: DropdownService,
  ) {}

  /**
   * Open the confirmation modal
   * @param action This parameter specify wording and UI.
   */
  open(action: ActionType): void {
    this.actionType = action;
    this.modalService.open(this.confirmationModal, {
      backdrop: 'static',
      centered: true,
    });
    this.loadDataMemoRejector();
  }

  onReasonChange(): void {
    this.reasonChange.emit(this.reason);
  }

  submit(): void {
    this.modalService.dismissAll();
    if (this.actionType === 'reject') {
      this.confirm.emit({
        pendingLevel: this.pendingLevel,
        reason: this.reason,
        type: this.typePosition,
      });
      this.memoRejectList = [];
      this.pendingLevel = null;
    } else {
      this.confirm.emit(this.confirmEmitValue);
    }
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
    this.memoRejectList = [];
    this.pendingLevel = null;
  }

  loadDataMemoRejector(): void {
    const type = 'memo_rejector';
    const params = {
      type,
      memo_id: this.memos?.id,
    };
    this.dropdownService
      .getDropdown(params)
      .subscribe((res: DropdownResponse) => {
        this.memoRejectList = res.memo_rejector;
        // Default dropdown value as Publisher
        if (this.memoRejectList.length > 0) {
          this.pendingLevel = this.memoRejectList[0].value;
        }
      });
  }

  onRejectChange(event: any): void {
    this.pendingLevel = event.value;
    if (event.context.position_en === 'Publisher') {
      this.typePosition = 'publisher';
    } else {
      this.typePosition = 'approver';
    }
  }

  contentText() {
    if (this.actionType === 'approve') {
      return this.translate.instant('APPROVAL.APPROVE-CONFIRMATION');
    } else if (this.actionType === 'reject') {
      return this.translate.instant('APPROVAL.REJECT-CONFIRMATION');
    } else {
      return this.translate.instant(
        'APPROVAL.TERMINATE-CONFIRMATION',
      );
    }
  }
}

export interface ConfirmModalOptions {
  confirmEmitValue?: any;
  enableReason?: boolean;
  reasonLabel?: string;
  personLabel?: string;
}
